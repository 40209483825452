export const PLACEHOLDERS = {
    CONTACT_IDENTIFIER: "contact.identifier",
    CONTACT_FIRST_NAME: "contact.firstName",
    CONTACT_LAST_NAME: "contact.lastName",
    CONTACT_EMAIL: "contact.email",
    CONTACT_PHONE: "contact.phone",
    CONTACT_FULL_NAME: "contact.fullName",
    SURVEY_URL: "survey_url",
} as const;

export const QUESTION_TYPES = {
    CHECKBOX: "checkbox",
    MATRIX: "matrix",
    RADIO_GROUP: "radiogroup",
}

export type PlaceholderKey = typeof PLACEHOLDERS[keyof typeof PLACEHOLDERS];
