import { AxiosResponse } from 'axios'
import axios from './axiosConfig';
import { Survey } from '../models/survey';
import { SurveyResponse, SurveyResponseCreate, SurveyResponseStatus } from '../models/surveyResponse';
import { Collector } from '../models/collector';
import { CloudinaryFile } from '../models/cloudinaryFile';

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const request = {
    get: <T>(url: string, headers = {}, params = {}) => axios.get<T>(url, { withCredentials: true, headers, params }).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body, { withCredentials: true }).then(responseBody),
    patch: <T>(url: string, body: {}) => axios.patch<T>(url, body, { withCredentials: true }).then(responseBody),
    postFormData: <T>(url: string, formData: FormData) => axios.post<T>(url, formData, { withCredentials: true, headers: { 'Content-Type': 'multipart/form-data' } }).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body, { withCredentials: true }).then(responseBody),
    delete: <T>(url: string, body?: {}) => axios.delete<T>(url, { withCredentials: true, data: body }).then(responseBody),
};

const Surveys = {
    getById: (id: number) => request.get<Survey>(`/survey/${id}`),
}

const SurveysResponses = {
    get: (responseId: string, withContact: boolean = false) => request.get<SurveyResponse>(`/survey-response/${responseId}?populateDynamicParameters=true&withContact=${withContact}`),
    create: (surveyId: number, response: SurveyResponseCreate) => request.post<{ surveyResponse: SurveyResponse, workflowResult?: any }>(`/survey-response/${surveyId}`, response),
    list: (surveyId: number) => request.get<SurveyResponse[]>(`/survey-response/survey/${surveyId}`),
    listByCollector: (collectorId: string) => request.get<SurveyResponse[]>(`/survey-response/collector/${collectorId}`),
    updateStatus: (responseId: string, status: SurveyResponseStatus) => request.patch<SurveyResponse>(`/survey-response/${responseId}/status`, { status }),
    delete: (responseId: string) => request.delete<void>(`/survey-response/${responseId}`),
    update: (response: SurveyResponse) => request.put<{ surveyResponse: SurveyResponse, workflowResult?: any }>(`/survey-response/${response.id}`, response),
    isCollectorAnswered: (collectorId: string) => request.get<boolean>(`/survey-response/collector/${collectorId}/answered`),
}

const Collectors = {
    getById: (id: string) => request.get<Collector>(`/collector/${id}`),
}

const Media = {
    uploadFile: (formData: FormData) => request.postFormData<CloudinaryFile>('/media/upload', formData),
    uploadFiles: (formData: FormData) => request.postFormData<CloudinaryFile[]>('/media/upload-multiple', formData),
    deleteFile: (fileName: string) => request.delete<void>(`/media/delete/${fileName}`),
    deleteAllFilesForResponse: (responseId: string) => request.delete<void>(`/media/delete-all/${responseId}`),
}

const Auth = {
    getSurveyToken: (searchParams: URLSearchParams) => request.get<string>(`/auth/take-survey/token`, {}, searchParams),
}

const agent = {
    Surveys,
    SurveysResponses,
    Collectors,
    Media,
    Auth,
}

export default agent;
