import React from 'react'
import { Box, Paper, Stack, Typography } from '@mui/material'
import { useStore } from '../app/stores/store';
import { observer } from 'mobx-react-lite';
import { Coupon } from '../app/models/Coupon';
import { useTranslation } from 'react-i18next';

interface Props {
    coupon: Coupon;
}

const CouponMessage = ({ coupon }: Props) => {
    const { t } = useTranslation();

    const { surveyStore } = useStore();
    const { selectedSurvey } = surveyStore;

    const logoURL = selectedSurvey?.content?.logo;

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{ height: "100vh", width: "100vw" }}
        >
            {logoURL ? (
                <Box
                    component="img"
                    src={logoURL}
                    alt="logo"
                    sx={{ width: "250px", height: "auto" }}
                />
            ) : null}
            <Typography variant="h4" align="center">
                {t('couponMessage.title')}
            </Typography>
            <Typography variant="h6" color='text.secondary' align="center" sx={{ pb: 2 }}>
                {t('couponMessage.subtitle')}
            </Typography>
            <Paper elevation={5} sx={{ p: 2, minWidth: '200px' }}>
                <Typography variant="subtitle2" align="center" gutterBottom>
                    {t('couponMessage.code')} <Typography variant='h5' fontWeight='bold'>{coupon.code}</Typography>
                </Typography>
                {coupon.group?.description ? (
                    <Typography variant="subtitle1" align="center">
                        {coupon.group?.description}
                    </Typography>
                ) : null}
            </Paper>
        </Stack>
    )
}

export default observer(CouponMessage);
