import { PLACEHOLDERS, PlaceholderKey } from './const';
import { Contact } from '../app/models/Contact';

export const getContactFullName = (contact: Contact): string => {
    const { firstName, lastName } = contact;
    if (firstName && lastName) {
        return `${firstName} ${lastName}`;
    }
    return firstName || lastName || "";
};

export const getSurveyUrl = (responseId: string): string => {
    return `${window.location.origin}/survey/${responseId}`;
};

export const getPlaceholderValues = (contact: Contact, responseId: string): Record<PlaceholderKey, string> => {
    return {
        [PLACEHOLDERS.CONTACT_IDENTIFIER]: contact.externalId || "",
        [PLACEHOLDERS.CONTACT_FIRST_NAME]: contact.firstName || "",
        [PLACEHOLDERS.CONTACT_LAST_NAME]: contact.lastName || "",
        [PLACEHOLDERS.CONTACT_EMAIL]: contact.email || "",
        [PLACEHOLDERS.CONTACT_PHONE]: contact.phone || "",
        [PLACEHOLDERS.CONTACT_FULL_NAME]: getContactFullName(contact),
        [PLACEHOLDERS.SURVEY_URL]: getSurveyUrl(responseId),
    };
};
