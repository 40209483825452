import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Model, SurveyModel, Serializer } from "survey-core";
import { Survey as SurveyComponent } from "survey-react-ui";
import { useStore } from "./../app/stores/store";
import { SurveyResponseCreate, SurveyResponseStatus } from "./../app/models/surveyResponse";
import { calculateMaxScore, calculateTotalScore, setDynamicParameters } from "./utils";
import ServerErrorPage from "./common/ServerErrorPage";
import { CollectorStatus } from "../app/models/collector";
import { useTranslation } from "react-i18next";
import useTakeSurvey from "./hooks/useTakeSurvey";
import { observer } from "mobx-react-lite";
import SurveyMessage from "./SurveyMessage";
import CouponMessage from "./CouponMessage";
import { useCookies } from "react-cookie";
import { ANSWERED_SURVEY_COOKIE_NAME_PREFIX } from "../app/core/consts";
import agent from "../app/api/agent";
import "survey-core/defaultV2.min.css";
import "survey-core/i18n/hebrew";
import useMesaureTime from "../app/hooks/useMeasureTime";

const HEBREW_LOCALE = "he";

Serializer.addProperty("itemvalue", {
    name: "score:number",
});

const TakeSurveyCollector = () => {
    const { t } = useTranslation();
    const { id: collectorId } = useParams<{ id?: string }>();
    const { startMeasure, endMeasure } = useMesaureTime();
    const [surveyFilledByIP, setSurveyFilledByIP] = useState<boolean>(false);
    const [hasServerError, setHasServerError] = useState<boolean>(false);
    const { handleFileClear, handleFileUpload } = useTakeSurvey();
    const { surveyResponseStore, surveyStore, commonStore, collectorStore } = useStore();
    const { setMessage, loadToken } = commonStore;
    const { selectedSurvey } = surveyStore;
    const { selectedCollector } = collectorStore;
    const { coupon, createSurveyResponse } = surveyResponseStore;
    const [cookies, _] = useCookies();
    const [hasFilledSurveyByCookie, setHasFilledSurveyByCookie] = useState<boolean>(false);

    const getHasFilledSurveyByCookie = () => Object.keys(cookies).some((key) => key.startsWith(ANSWERED_SURVEY_COOKIE_NAME_PREFIX) && key.split(ANSWERED_SURVEY_COOKIE_NAME_PREFIX)[1] === collectorId);

    const fetchSurveyData = async () => {
        try {
            await loadToken(collectorId);

            if (!collectorId) {
                setMessage({ message: t("takeSurvey.errors.surveyMissing"), type: "error" });
                return;
            }

            const collector = await collectorStore.loadCollector(collectorId);

            if (collector.limitByCookie && getHasFilledSurveyByCookie()) {
                setHasFilledSurveyByCookie(true);
                return;
            }

            if (collector.limitByIp && (await agent.SurveysResponses.isCollectorAnswered(collectorId))) {
                setSurveyFilledByIP(true);
                return;
            }

            const survey = await surveyStore.loadSelectedSurvey(collector.surveyId);
            if (!survey) {
                setMessage({ message: t("takeSurvey.errors.surveyMissing"), type: "error" });
                return;
            }

            startMeasure();
        } catch (error) {
            setHasServerError(true);
            setMessage({ message: t("takeSurvey.errors.surveyDataFetchError"), type: "error" });
        }
    };

    useEffect(() => {
        fetchSurveyData();
    }, []);

    const handleSurveyComplete = async (sender: SurveyModel) => {
        if (!collectorId || !selectedCollector) {
            setMessage({ message: t("takeSurvey.errors.surveyMissing"), type: "error" });
            return;
        }
        const duration = endMeasure("second") || 0;
        const plainData = sender.getPlainData({
            calculations: [{ propertyName: "score" }],
        });
        const maxScore = calculateMaxScore(sender.getAllQuestions());
        const totalScore = calculateTotalScore(plainData, sender);

        sender.setValue("maxScore", maxScore);
        sender.setValue("totalScore", totalScore);

        try {
            const response: SurveyResponseCreate = {
                status: SurveyResponseStatus.COMPLETED,
                content: sender.data,
                method: "web",
                collectorId: collectorId,
                surveyId: selectedCollector!.surveyId,
                maxScore: maxScore,
                totalScore: totalScore,
                duration,
            };

            await createSurveyResponse(selectedCollector.surveyId, response);

            setMessage({ message: t("takeSurvey.success.surveyResponseCreated"), type: "success" });
        } catch (error) {
            setHasServerError(true);
            setMessage({ message: t("takeSurvey.errors.surveyResponseUpdateError"), type: "error" });
        }
    };

    const surveyModel = useMemo(() => {
        if (!selectedSurvey?.content) {
            return null;
        }

        const model = new Model(selectedSurvey.content);

        if (selectedCollector?.dynamicParameters) {
            setDynamicParameters(model, selectedCollector.dynamicParameters);
        }

        if (selectedSurvey?.theme) {
            model.applyTheme(selectedSurvey.theme.theme);
        }

        return model;
    }, [selectedSurvey, selectedCollector]);

    if (hasServerError) {
        return <ServerErrorPage />;
    }

    if (hasFilledSurveyByCookie || surveyFilledByIP) {
        return <SurveyMessage message={t("takeSurvey.errors.surveyFilledError")} />;
    }

    if (selectedCollector && selectedCollector.status === CollectorStatus.CLOSED) {
        return <SurveyMessage message={selectedCollector.closedMessage} />;
    }

    if (coupon) {
        return <CouponMessage coupon={coupon} />;
    }

    return (
        <>
            {selectedSurvey?.content && (
                <SurveyComponent
                    model={surveyModel}
                    onCompleting={handleSurveyComplete}
                    onUploadFiles={(_: Model, options: any) => handleFileUpload(options)}
                    onClearFiles={(_: SurveyModel, options: any) => handleFileClear(options)}
                    locale={selectedSurvey.content?.locale || HEBREW_LOCALE}
                />
            )}
        </>
    );
};

export default observer(TakeSurveyCollector);
